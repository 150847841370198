import { PagedCollectionFilter, SimpleLookupModel } from "@/core/models";

export class EducatorFilterModel extends PagedCollectionFilter {
  startDate: Date|undefined = undefined;
  endDate: Date|undefined = undefined;
  strandIds: number[] = [];
  courseIds: number[] = [];
  regionIds: number[] = [];
  unionIds: number[] = [];
  localeIds: number[] = [];
}

export class EducatorCollectionModel {
  userId: number = 0;
  firstName: string = "";
  lastName: string = "";
  displayName: string = "";
  email: string = "";
  homeUnionName: string = "";
  numberOfCoursesDelivered: number = 0;
  numberOfUnionsDeliveredIn: number = 0;
}

export class EducatorLookupModel {
  levels: SimpleLookupModel[] = [];
  locales: SimpleLookupModel[] = [];
  strands: SimpleLookupModel[] = [];
  courses: SimpleLookupModel[] = [];
  regions: SimpleLookupModel[] = [];
  unions: SimpleLookupModel[] = [];
  genders: SimpleLookupModel[] = [];
}
